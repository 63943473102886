var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout--main",class:[
    _vm.layoutTypeClass,
    _vm.navbarClasses,
    _vm.footerClasses,
    { 'no-scroll': _vm.isAppPage }
  ]},[_c('div',{class:[_vm.contentAreaClass, { 'show-overlay': _vm.bodyOverlay }],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),[_c('the-navbar-vertical',{class:[
          { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
          { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark }
        ],attrs:{"navbarColor":_vm.navbarColor}})],_c('div',{staticClass:"content-wrapper sm:px-0 md:px-4 lg:px-24 xl:px-64"},[_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content"},[_c('router-view')],1)])]),_c('the-footer')],2)])}
var staticRenderFns = []

export { render, staticRenderFns }